<template>
  <nav class="navbar navbar-expand-lg" style="">
    <div class="container-fluid">
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="nav navbar-nav mr-auto">
          <div style="padding-top: 22px; padding-right: 10px">
            <img style="width: 40px; height: 40px" src="static/favicon.png" />
          </div>
          
          <h4>
            
            <span style="margin-top: 20px">Knows - Testing and knowledge base platform</span>
          </h4>
        </ul>

      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "public-top-navbar",
  data() {
    return {};
  },
 
};
</script>
<style>
</style>
