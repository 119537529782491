<template>
  <card
    ><h3 v-if="!notfound">Redirecting...</h3>
    <h3 v-else>İstifadəçi tapılmadı</h3></card
  >
</template>

<script>
import { GenerateToken } from "../services/crypto";
import { Auth, UserIdByEmail, Register, Activate } from "../services/user";
import { SetUser, SetCookie, LoadCookie } from "../services/localstate";
export default {
  data() {
    return {
      model: { email: "kbdemo@huestimates.com", pwd: "1" },
      notfound: false,
    };
  },
  mounted() {
    this.model.notfound = false;
    UserIdByEmail(this.model).then((r1) => {
      if (r1.data.result.id !== null) {
        Auth(this.model.email, this.model.pwd).then((response) => {
          if (response.data.result) {
            let user = response.data.result;
            SetCookie("token", GenerateToken());
            SetUser(user);
            SetCookie("rememberme", false);
            if (user.roleid === 3)
              this.$router.push({ name: "AccountMain" }).catch((e) => {});
          } else this.notfound = true;
        });
      } else {
        this.notfound = true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>