import axios from "axios";
import NProgress from "nprogress";


const client = axios.create({
  baseURL: "https://api.huestimates.com/app/mail/",
  // baseURL: "http://localhost:9000/",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "null",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With"
  }
});

client.interceptors.request.use(config => {
  NProgress.start();
  return config;
});

function SendMail(msg) {
  return client.post("send.php", msg);
}

export {
  SendMail
};
