<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <ValidationObserver v-slot="{ handleSubmit }">
      <card>
        
        <div class="row align-items-center">
          <div class="col-12">
          <l-button type="default" wide outline @click="onBack()">
            <i class="nc-icon left-arrow"></i>
            Ana səhifə
          </l-button>
        </div>
          <div class="col-12">
            <h3 class="mb-0">Profildə düzəliş</h3>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-3 order-lg-2">
            <div class="card-profile-image">
              <a href="#">
                <img class="profile-picture-sm" :src="picture" />
              </a>
            </div>
          </div>
        </div>
        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <div class="d-flex justify-content-between"></div>
        </div>
        <h6 class="heading-small text-muted mb-4">Şəkli dəyiş</h6>

        <el-upload
          :action="uploadurl"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-success="afterUpload"
        >
          <button size="small" class="btn btn-fill btn-default btn-wd">
            Yüklə
          </button>
        </el-upload>

        <br />
        <br />
        <h6 class="heading-small text-muted mb-4">İstifadəçinin məlumatı</h6>

        <div class="pl-lg-4">
          <div class="row">
            <div class="col-lg-6">
              <ValidationProvider
                name="fullname"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="text"
                  :error="failed ? 'The Full Name field is required' : null"
                  :hasSuccess="passed"
                  label="Tam adı"
                  placeholder="Tam adı"
                  v-model="currentUser.fullname"
                  name="Tam adı"
                ></fg-input>
              </ValidationProvider>
            </div>
            <div class="col-lg-6">
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="email"
                  label="Email"
                  placeholder="user@email.com"
                  v-model="currentUser.email"
                  :error="failed ? 'The Full Name field is required' : null"
                  :hasSuccess="passed"
                  name="email"
                  :disabled="true"
                ></fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <ValidationProvider
                name="phone"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="number"
                  label="Telefon"
                  placeholder="Telefon"
                  v-model="currentUser.phone"
                  name="phone"
                  :error="failed ? 'The Full Name field is required' : null"
                  :hasSuccess="passed"
                ></fg-input>
              </ValidationProvider>
            </div>
            <div class="col-lg-6">
              <fg-input
                label="Doğum tarixi"
                rules="required"
                name="Doğum tarixi"
              >
                <el-date-picker
                  v-model="currentUser.dob"
                  type="date"
                  placeholder="Date picker here"
                  :picker-options="pickerOptions1"
                >
                </el-date-picker>
              </fg-input>
            </div>
          </div>
        </div>
        <hr class="my-4" />

        <!-- Ünvan -->
        <h6 class="heading-small text-muted mb-4">Əlaqə</h6>

        <div class="pl-lg-4">
          <div class="row">
            <div class="col-md-12">
              <fg-input
                type="text"
                label="Ünvan"
                placeholder="Ünvan"
                v-model="currentUser.address"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <fg-input
                type="text"
                label="Şəhər"
                placeholder="Şəhər"
                v-model="currentUser.city"
              ></fg-input>
            </div>
            <div class="col-lg-6">
              <fg-input
                type="text"
                label="Ölkə"
                placeholder="Ölkə"
                v-model="currentUser.country"
              ></fg-input>
            </div>
          </div>
        </div>

        <hr class="my-4" />
        <!-- Təsvir -->

        <h6 class="heading-small text-muted mb-4">Əlavə məlumat</h6>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col-lg-6">
              <fg-input
                type="text"
                label="Company"
                placeholder="Company"
                v-model="currentUser.company"
              ></fg-input>
            </div>
            <div class="col-lg-6">
              <fg-input
                type="text"
                label="Position"
                placeholder="Position"
                v-model="currentUser.position"
              ></fg-input>
            </div>
          </div>
        </div>
        <div class="pl-lg-4">
          <div class="form-group">
            <label class="form-control-label">Mən barədə</label>
            <textarea
              rows="4"
              class="form-control"
              placeholder="A few words about you ..."
              v-model="currentUser.aboutme"
            ></textarea>
          </div>
        </div>
        <div class="pl-lg-0" style="text-align: center">
          <button
            v-if="$store.state.user.roleid !== 3"
            @click.prevent="handleSubmit(onSave)"
            class="btn btn-fill btn-primary btn-wd"
          >
            Yadda saxla
          </button>
        </div>
      </card>
    </ValidationObserver>
  </div>
</template>
<script>
import { uploadUrl, fileUrl, nophoto } from "../../services/helper";
import { GetUser, Update, Create } from "../../services/user";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import store from "../../store";
import { color } from "d3";
import { SendMail } from "../../services/mail";
import { resetpwd, newuser } from "../../services/mtmpl";
import { GeneratePwd, Encrypt, GenerateHex } from "../../services/crypto";
import { UserType, FileType } from "../../services/types";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import {
  DatePicker,
  TimeSelect,
  Slider,
  Tag,
  Input,
  Button,
  Select,
  Option,
  Upload,
} from "element-ui";
import {
  Progress as LProgress,
  Switch as LSwitch,
  Radio as LRadio,
  Checkbox as LCheckbox,
  FormGroupInput as FgInput,
} from "src/components/index";

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    FgInput,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload,
    Loading,
  },
  computed: {
    uploadurl() {
      return uploadUrl;
    },
    fileurl() {
      return fileUrl;
    },
    picture() {
      return !this.currentUser.picture
        ? nophoto
        : this.fileurl + this.currentUser.picture;
    },
  },
  data() {
    return {
      isLoading: false,
      modals: {
        classic: false,
        notice: false,
        form: false,
      },
      currentUserId: this.$store.state.user.id,
      currentUser: this.$store.state.user,
      model: {
        to: "",
        body: "",
        subject: "",
        alias: "HU Knows",
      },
      sent: false,
      pickerOptions1: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    onBack() {
      this.$router
        .push({
          path:
            this.$store.state.user.roleid === 1
              ? "/admin/main"
              : "/account/main",
        })
        .catch((e) => {});
    },
    onSave() {
      this.isLoading = true;
      Update(this.currentUser).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.notifyVue("success");
          this.$store.dispatch("updateProfileData", this.currentUser);
        } else {
          this.notifyVue("danger", response.data);
        }
      });
    },
    beforeUpload(file) {
      let fname = file.name;
      let key = GenerateHex();
      let newname = key + "_" + fname;
      Object.defineProperty(file, "name", {
        writable: true,
        value: newname,
      });
    },

    afterUpload(response, file, fileList) {
      if (!response.result) {
        this.notifyVue("danger", "Action failed!");
        return;
      }

      var f = new FileType();
      f.filename = file.raw.name;
      f.sourcetype = 9;
      f.sourceId = this.currentUserId;

      this.isLoading = true;
      this.$store.dispatch("updateProfilePicture", f).then((response) => {
        this.isLoading = false;
        if (response) {
          this.notifyVue("success", "Changes have been saved!");
          this.currentUser.picture =
            new Date().getFullYear().toString() + "/" + f.filename;
        } else this.notifyVue("danger", "Action failed!");
      });
    },
  },
  created() {
    this.currentUser = Object.assign({}, this.$store.state.user);
  },
};
</script>
<style>
.profile-picture-sm {
  /* width: 50px; */
  height: 50px;
}
</style>
