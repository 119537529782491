<template>
  <nav class="navbar navbar-expand-lg" style="">
    <div class="container-fluid">
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="nav navbar-nav mr-auto">
         
          <div>
            <table>
              <tr>
                <td><img src="static/favicon.png" width="28px" /></td>
                <td style="font-size: 19pt; padding-top: 1px"><span> Knows </span> - Testing and knowledge base service</td>
              </tr>
            </table>
          </div>
        </ul>
        <ul class="navbar-nav">
          <drop-down position="right">
            <!-- <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img
                class="profile-picture-sm"
                alt="Image placeholder"
                :src="$store.getters.profilephoto"
              />
            </span>
            <div
              class="media-body ml-2 d-none d-lg-block"
              style="padding-left: 5px"
            >
              <span class="mb-0 text-sm font-weight-bold">{{
                $store.state.user.fullname
              }}</span>
            </div>
          </div>
        </a> -->

            <a href="#" @click.prevent slot="title">
              <div class="media align-items-center">
                <span class="avatar avatar-sm"></span>
                <img
                  class="profile-picture-sm"
                  alt="photo"
                  :src="$store.getters.profilephoto"
                />

                <div
                  class="media-body ml-2 d-none d-lg-block"
                  style="padding-left: 5px"
                >
                  <span class="mb-0 text-sm font-weight-bold">{{
                    $store.state.user.fullname
                  }}</span>
                </div>
              </div>
            </a>

            <router-link
              :to="{
                name: 'Profile',
                params: { id: $store.state.user.id, filter: 'none' },
              }"
              class="dropdown-item"
            >
              <i class="nc-icon a-edit"></i>
              <span style="padding-left: 5px">My profile</span>
            </router-link>
            <router-link :to="{ name: 'ChangePassword' }" class="dropdown-item">
              <i class="nc-icon padlock-unlocked"></i>
              <span style="padding-left: 5px">Change password</span>
            </router-link>

            <div class="dropdown-divider"></div>
            <router-link
              :to="{ name: 'Logout' }"
              class="dropdown-item text-danger"
            >
              <i class="nc-icon log-out"></i>
              <span style="padding-left: 5px">Logout</span>
            </router-link>
          </drop-down>
          <!-- <drop-down position="right">
            <i slot="title" class="nc-icon nc-bullet-list-67"></i>

            <a class="dropdown-item" href="#">
              <i class="nc-icon nc-email-85"></i> Messages
            </a>
            <a class="dropdown-item" href="#">
              <i class="nc-icon nc-umbrella-13"></i> Help Center
            </a>
            <a class="dropdown-item" href="#">
              <i class="nc-icon nc-settings-90"></i> Settings
            </a>
            <div class="divider"></div>
            <a class="dropdown-item" href="#">
              <i class="nc-icon nc-lock-circle-open"></i> Lock Screen
            </a>
            <a href="#" class="dropdown-item text-danger">
              <i class="nc-icon nc-button-power"></i> Log out
            </a>

          </drop-down> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {};
  },
  // methods: {
  //   capitalizeFirstLetter (string) {
  //     return string.charAt(0).toUpperCase() + string.slice(1)
  //   },
  //   toggleNotificationDropDown () {
  //     this.activeNotifications = !this.activeNotifications
  //   },
  //   closeDropDown () {
  //     this.activeNotifications = false
  //   },
  //   toggleSidebar () {
  //     this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
  //   },
  //   hideSidebar () {
  //     this.$sidebar.displaySidebar(false)
  //   },
  //   minimizeSidebar () {
  //     this.$sidebar.toggleMinimize()
  //   }
  // }
};
</script>
<style>
.profile-picture-sm {
  /* width: 50px; */
  height: 50px;
}
</style>
