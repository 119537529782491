<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <notifications></notifications>
    <div class="header bg-gradient-success py-7 py-lg-4 pt-lg-1">
      <div class="container">
        <div class="header-body text-center">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8">
              <h2 class="text-white">Şifrəni yenilə!</h2>
              <p v-if="!resetkey" class="text-lead text-white">
                Xahiş edirik hesabınızın Email ünvanını daxil edin 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form role="form" @submit.prevent="handleSubmit(onReset)">
            <fade-render-transition>
              <card>
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    type="email"
                    v-if="!resetkey"
                    :error="failed ? 'The Email field is required' : null"
                    :hasSuccess="passed"
                    label="Email"
                    name="email"
                    v-model="email"
                  >
                  </fg-input>
                </ValidationProvider>
                <div v-if="resetkey && invalidkey">
                  <div class="text-center">
                    <span>Səhv baş verdi. Əməliyyatı təkrarlayın.</span>
                  </div>
                </div>
                <div v-if="resetkey && !invalidkey">
                  <span>
                    Hesab
                    <b>{{ email }}</b>
                  </span>

                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="password"
                      :error="failed ? 'The Password field is required' : null"
                      :hasSuccess="passed"
                      name="password"
                      label="Şifrə"
                      v-model="pwd"
                    >
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    v-if="!invalidkey"
                    class="btn btn-fill btn-info btn-round btn-wd"
                    @click.prevent="onReset"
                  >
                    Yenilə
                  </button>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { FadeRenderTransition } from "src/components/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { SendMail } from "../services/mail";
import { resetpwd } from "../services/mtmpl";
import { GeneratePwd, Encrypt } from "../services/crypto";
import {
  Reset,
  UserIdByEmail,
  SendResetEmail,
  EmailByResetKey,
} from "../services/user";
export default {
  components: { FadeRenderTransition, Loading },
  props: {
    resetkey: String,
  },
  data() {
    return {
      isLoading: false,
      pwd: "",
      email: "",
      invalidkey: false,
    };
  },
  methods: {
    onReset() {
      let user = {};
      user.email = this.email;
      this.isLoading = true;
      this.notfound = false;
      if (this.resetkey) {
        const newpwd = Encrypt(this.pwd, null);
        user.resetkey = this.resetkey;
        user.pwd = newpwd.key;
        user.salt = newpwd.salt;
        Reset(user).then((response) => {
          this.isLoading = false;
          this.notifyVue("success", "Password has been changed successfully!");
          this.$router.push({ name: "Login" });
        });
      } else {
        UserIdByEmail(user).then((r1) => {
          if (r1.data.result.id !== null) {
            SendResetEmail(user).then((r2) => {
              this.isLoading = false;
              this.notifyVue(
                "success",
                "Recovery link has been sent to your email!"
              );
            });
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "User was not found!");
          }
        });
      }
    },
  },
  mounted() {
    if (!this.resetkey) return;
    else this.invalidkey = false;
    this.isLoading = true;
    EmailByResetKey(this.resetkey).then((response) => {
      this.isLoading = false;
      if (response.data.result !== undefined) {
        this.email = response.data.result;
        this.invalidkey = false;
      } else {
        this.invalidkey = true;
      }
    });
  },
};
</script>