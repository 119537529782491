<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>

    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->

        <card>
          <small slot="header"
            >Hesabınız varsa Daxil olun və ya Qeydiyyatdan keçin</small
          >
          <el-tabs v-model="activeName">
            <el-tab-pane label="Daxil" name="Login">
              <ValidationObserver v-slot="{ handleSubmit }">
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="email"
                      :error="failed ? 'The Email field is required' : null"
                      :hasSuccess="passed"
                      label="Email address"
                      name="email"
                      v-model="model.email"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="password"
                      :error="failed ? 'The Password field is required' : null"
                      :hasSuccess="passed"
                      name="password"
                      label="Password"
                      v-model="model.password"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <fg-input>
                    <l-checkbox v-model="model.rememberme">
                      Remember me
                    </l-checkbox>
                  </fg-input>
                </div>
                <div class="text-center">
                  <button
                    @click.prevent="handleSubmit(onSubmit)"
                    type="submit"
                    class="btn btn-fill btn-info btn-wd"
                  >
                    Login
                  </button>
                  <small style="color: red" v-if="model.notfound">
                    <br />
                    User doesn't exist or password is incorrect
                  </small>
                  <small style="color: red" v-if="model.notactivated">
                    <br />
                    Account is not activated
                  </small>
                  <br />
                  <div class="forgot">
                    <router-link to="/reset" class="card-category">
                      Forgot your password?
                    </router-link>
                  </div>
                </div>
              </ValidationObserver>
            </el-tab-pane>
            <el-tab-pane label="Qeydiyyat" name="Register">
              <ValidationObserver v-slot="{ handleSubmit }">
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="email"
                      :error="failed ? 'The Email field is required' : null"
                      :hasSuccess="passed"
                      label="Email"
                      name="email"
                      v-model="reg.email"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="fullname"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="text"
                      :error="failed ? 'The Name field is required' : null"
                      :hasSuccess="passed"
                      label="Tam adınız"
                      name="fullname"
                      v-model="reg.fullname"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      :type="showpwd ? 'text' : 'password'"
                      :error="
                        failed
                          ? 'The Password field is required. At least 5 characters.'
                          : null
                      "
                      :hasSuccess="passed"
                      name="password"
                      label="Şifrə"
                      v-model="mypwd"
                    >
                    </fg-input>
                    <fg-input>
                    <l-checkbox v-model="showpwd">
                      Şifrəni göstər
                    </l-checkbox>
                  </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button
                    @click.prevent="handleSubmit(onRegister)"
                    type="submit"
                    class="btn btn-fill btn-info btn-wd"
                  >
                    Hesab yarat
                  </button>
                </div>
              </ValidationObserver>
            </el-tab-pane>
          </el-tabs>
        </card>
        <el-dialog
          center
          title="Enter PIN"
          :visible.sync="pinModal"
          :append-to-body="true"
          width="30%"
        >
          <div class="row">
            <div class="col-lg-12 text-center">
              <PincodeInput v-model="pin" placeholder="" :autofocus="true" />
            </div>
          </div>

          <div slot="footer">
            <button class="btn btn-success" @click="onActivate">Ok</button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// import { extend } from "vee-validate";
// import { required, email, min } from "vee-validate/dist/rules";

import {
  Encrypt,
  GenerateToken,
  GeneratePwd,
  EncryptAES,
} from "../services/crypto";
import { Auth, UserIdByEmail, Register, Activate } from "../services/user";
import { SetUser, SetCookie, LoadCookie } from "../services/localstate";
import { Tabs, TabPane, Dialog } from "element-ui";
import PincodeInput from "vue-pincode-input";

// extend("email", email);
// extend("required", required);
// extend("min", min);

export default {
  components: {
    Loading,
    LCheckbox,
    // FadeRenderTransition,
    [Dialog.name]: Dialog,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    PincodeInput,
  },
props: {
  action: {
    type: String,
    default: "Login"
  },
  email: "",
  name: ""
},
  data() {
    return {
      isLoading: false,
      activeName: this.action,
      mypwd: "",
      pinModal: false,
      showpwd: false,
      pin: "",
      reg: {
        email: this.email,
        pwd: "",
        pwdtoken: "",
        fullname: this.name,
        exists: false,
      },
      model: {
        email: "",
        password: "",
        rememberMe: false,
        notfound: false,
        notactivated: false,
      },
    };
  },
  methods: {
    showSwal(type) {
      if (type === "basic") {
        Swal.fire({
          title: `Here's a message!`,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-success btn-fill",
        });
      } else if (type === "exist-message") {
        Swal.fire({
          title: `Email is already exists!`,
          text: `If you forgot your password, try to recover from Login tab.`,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-info btn-fill",
        });
      } else if (type === "success-message") {
        Swal.fire({
          title: `Good job!`,
          text: "Your account has been activated!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-success btn-fill",
          type: "success",
        });
      } else if (type === "warning-message") {
        Swal.fire({
          title: "Account validation failed?",
          text: `PIN is wrong! Please try Sign up again.`,
          type: "danger",
          showCancelButton: false,
          confirmButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
        }).then(function () {
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
      }
    },
    onSubmit() {
      this.model.notfound = false;
      this.isLoading = true;
      UserIdByEmail(this.model).then((r1) => {
        if (r1.data.result.id !== null) {
          if (r1.data.result.activated === 0) {
            this.model.notactivated = true;
            return;
          }
          const pwd = Encrypt(this.model.password, r1.data.result.pwdtoken);
          Auth(this.model.email, pwd.key).then((response) => {
            this.isLoading = false;
            if (response.data.result) {
              this.proceedUser(response.data.result);
            } else this.model.notfound = true;
          });
        } else {
          this.model.notfound = true;
          this.isLoading = false;
        }
      });
    },

    onRegister() {
      this.pin = "";
      this.reg.exists = false;
      this.isLoading = true;
      const pwd = Encrypt(this.mypwd, null);
      this.reg.pwd = pwd.key;
      this.reg.pwdtoken = pwd.salt;
      Register(this.reg).then((response) => {
        this.isLoading = false;
        if (!response.data.result) {
          this.showSwal("exist-message");
        } else {
          this.pinModal = true;
        }
      });
    },

    onActivate() {
      if (!this.pin) return;
      this.pinModal = false;
      this.isLoading = true;
      let avt = { email: this.reg.email, pin: this.pin, res: false };
      Activate(avt).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          avt.res = response.data.result;
          if (avt.res) {
            this.showSwal("success-message");
            this.activeName = "Login";
            this.model.email = avt.email;
            this.model.pwd = "";
          } else this.showSwal("warning-message");
        }
      });
    },

    proceedUser(user) {
      SetCookie("ktoken", GenerateToken());
      SetUser(user);
      SetCookie("rememberme", this.model.rememberMe);
      if (user.roleid === 1)
        this.$router.push({ name: "AdminMain" }).catch((e) => {});
      if (user.roleid === 2)
        this.$router.push({ name: "AccountMain" }).catch((e) => {});
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
