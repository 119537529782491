<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">

      <div class="copyright">
        &copy; Developed by <a href="https://www.huestimates.com" target="_blank" rel="noopener">HU Estimates</a>.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
