import axios from "axios";
import Vue from "vue";
import { api } from "./helper";
const client = axios.create({
  baseURL: api,
  withCredentials: false
});

client.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.reject(error.response);
});

function Create(file) {
  return client.post("files.php", file);
}

function GetFiles(sourcetype, sourceId, sourcedetId) {
    const params = {
        sourcetype: sourcetype,
        sourceId: sourceId,
        sourcedetId: sourcedetId
    };
    return client.get("files.php", { params });
  }
  
  function Delete(id) {
    const params = {
      id: id
    };
    return client.delete("files.php", { params });
  }

  export { Create, GetFiles, Delete }