var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"name":"lp","active":_vm.isLoading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('notifications'),_c('div',{staticClass:"header bg-gradient-success py-7 py-lg-4 pt-lg-1"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header-body text-center"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-5 col-lg-6 col-md-8"},[_c('h2',{staticClass:"text-white"},[_vm._v("Şifrəni yenilə!")]),(!_vm.resetkey)?_c('p',{staticClass:"text-lead text-white"},[_vm._v(" Xahiş edirik hesabınızın Email ünvanını daxil edin ")]):_vm._e()])])])])]),_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onReset)}}},[_c('fade-render-transition',[_c('card',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [(!_vm.resetkey)?_c('fg-input',{attrs:{"type":"email","error":failed ? 'The Email field is required' : null,"hasSuccess":passed,"label":"Email","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}):_vm._e()]}}],null,true)}),(_vm.resetkey && _vm.invalidkey)?_c('div',[_c('div',{staticClass:"text-center"},[_c('span',[_vm._v("Səhv baş verdi. Əməliyyatı təkrarlayın.")])])]):_vm._e(),(_vm.resetkey && !_vm.invalidkey)?_c('div',[_c('span',[_vm._v(" Hesab "),_c('b',[_vm._v(_vm._s(_vm.email))])]),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Password field is required' : null,"hasSuccess":passed,"name":"password","label":"Şifrə"},model:{value:(_vm.pwd),callback:function ($$v) {_vm.pwd=$$v},expression:"pwd"}})]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"text-center"},[(!_vm.invalidkey)?_c('button',{staticClass:"btn btn-fill btn-info btn-round btn-wd",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onReset($event)}}},[_vm._v(" Yenilə ")]):_vm._e()])],1)],1)],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }