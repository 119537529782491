import Vue from "vue";
import Vuex from "vuex";
import { LoadUser, SaveStatePlugin } from "./services/localstate";
import { Create } from "./services/files";
import { nophoto,  fileUrl  } from "./services/helper";
Vue.use(Vuex);

const curruser = LoadUser();

export default new Vuex.Store({
  plugins: [SaveStatePlugin],
  state: {
    user: curruser
  },
  mutations: {
    UPDATE_USER(state, user){
      state.user.fullname = user.fullname;
      state.user.dob = user.dob;
      state.user.phone = user.phone;
      state.user.picture = user.picture;
      state.user.company = user.company;
      state.user.position = user.position;
      state.user.address = user.address;
      state.user.country = user.country;
      state.user.city = user.city;
    },
    UPDATE_PICTURE(state, f) {
      state.user.picture = f;
    },
    LOGOUT_USER(state){
      state.user = null;
      localStorage.clear();
    }
  },
  actions: {
    updateProfileData({ commit }, user){
      commit('UPDATE_USER', user)
    },
    updateProfilePicture({ commit, state }, f) {
      return Create(f).then((response) => {
        if (response.data) {
          if (f.sourceId === state.user.id)
            commit("UPDATE_PICTURE", new Date().getFullYear() + "/" + f.filename);
          return true;
        } else return false;
      });
    },
    logout({ commit }){
      commit('LOGOUT_USER')
    },
  },
  getters: {
    age: state => {
      var today = new Date();
      var birthDate = new Date(state.user.dob);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
      }

      return age;
    },
    profilephoto: state => {
      return state.user.picture=== null ? nophoto : fileUrl + state.user.picture;
    }
  }
});
