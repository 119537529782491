import { EncryptAES, DecryptAES } from "./crypto";
import store from "../store";



function SetUser(user) {
  if (user === null) {
    localStorage.removeItem("kusr");
  } else {
    localStorage.setItem(
      "kusr",
      EncryptAES(JSON.stringify(user), LoadCookie("ktoken"))
    );
    store.state.user = user;
  }
  return;
}

function LoadUser() {
  if (localStorage.getItem("kusr") !== null)
    try {
      return JSON.parse(
        DecryptAES(localStorage.getItem("kusr"), LoadCookie("ktoken"))
      );
    } catch (err) {
      return err;
    }
    return null;
}

function SetCookie(name, value) {
  $cookies.set(name, value, "30d");
  return;
}

function LoadCookie(name) {
  if ($cookies.isKey(name)) return $cookies.get(name);
}

function SaveStatePlugin(store) {
  store.subscribe((mutation, state) => {
    SetUser(state.user);
    // console.log("set user");
  });
}

export { SetUser, LoadUser, SetCookie, LoadCookie, SaveStatePlugin };
