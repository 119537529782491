import Vue from "vue";
import VueRouter from "vue-router";
import LightBootstrap from "./light-bootstrap-main";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";
import store from "./store";
// Plugins
import App from "./App.vue";

// router setup
import routes from "./routes/routes";
// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(VueCookies);

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

import VueCountdownTimer from "vuejs-countdown-timer";
Vue.use(VueCountdownTimer);

Vue.mixin({
  methods: {
    notifyVue(type = "default", text) {
      if (!text) {
        if (type === "success") {
          text = "Changes have been saved!";
        } else if (type === "danger") {
          text = "Action failed!";
        }
      }
      this.$notify({
        timeout: 5000,
        message: text,
        horizontalAlign: "right",
        verticalAlign: "top",
        type: type,
        icon: "nc-icon bell"
      });
    }
  }
});
Vue.prototype.$apiUrl = "https://api.huestimates.com/app/know/controller";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  const metaauth = to.matched.find(record => record.meta.requiresAuth);
  const metarole = to.matched.find(record => record.meta.role);
  if (to.path === "/logout") {
    store.dispatch("logout");
    next({
      path: "/login"
    });
    return;
  }
  if (metaauth) {
    if (metaauth.meta.requiresAuth) {
      let user = store.state.user;
      if (user === null) {
        next({
          name: "Login",
          params: { nextUrl: to.fullPath }
        });
      } else {
        if (metarole) {
          if (metarole.meta.role.indexOf(store.state.user.roleid) > -1) {
            next();
          } else {
            next({
              name: "NotFound"
            });
          }
        } else next();
      }
    }
  } else next();
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  store,
  router
});
