import store from "../store";
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import PublicLayout from "src/pages/Dashboard/Layout/PublicLayout.vue";
import AuthLayout from "src/pages/AuthLayout.vue";
// Pages
import User from "src/pages/UserProfile.vue";
import Login from "src/pages/Login.vue";
import Reset from "src/pages/Reset.vue";
import Demo from "src/pages/Demo.vue";

const NotFound = () => import("src/pages/NotFound.vue");

const AdminMain = () => import("src/pages/Admin/Main.vue");
const Category = () => import("src/pages/Admin/Category.vue");
const Tests = () => import("src/pages/Admin/Tests.vue");
const Test = () => import("src/pages/Admin/Test.vue");
const Share = () => import("src/pages/Admin/Share.vue");
const Sessions = () => import("src/pages/Admin/Sessions.vue");

const AccountMain = () => import("src/pages/Account/Main.vue");
const Start = () => import("src/pages/Account/Start.vue");
const Session = () => import("src/pages/Account/Session.vue");
const Result = () => import("src/pages/Account/Result.vue");
const Shared = () => import("src/pages/Account/Shared.vue");
const Recent = () => import("src/pages/Account/Recent.vue");
const Taked = () => import("src/pages/Account/Taked.vue");

const PublicStart = () => import("src/pages/Public/Start.vue");
const PublicSession = () => import("src/pages/Public/Session.vue");
const PublicResult = () => import("src/pages/Public/Result.vue");

const ChangePassword = () => import("src/pages/ChangePwd.vue");
const Profile = () => import("src/pages/UserProfile.vue");

let adminPages = {
  path: "/admin",
  component: DashboardLayout,
  redirect: "/admin/main",
  children: [
    {
      path: "main",
      name: "AdminMain",
      component: AdminMain,
      meta: {
        requiresAuth: true,
        role: [1]
      }
    },
    {
      path: "category",
      name: "Category",
      component: Category,
      meta: {
        requiresAuth: true,
        role: [1]
      }
    },
    {
      path: "tests",
      name: "Tests",
      component: Tests,
      meta: {
        requiresAuth: true,
        role: [1]
      }
    },
    {
      path: "test/:id?",
      name: "Test",
      component: Test,
      props: true,
      meta: {
        requiresAuth: true,
        role: [1]
      }
    },
    {
      path: "share",
      name: "Share",
      component: Share,
      meta: {
        requiresAuth: true,
        role: [1]
      }
    },
    {
      path: "sessions/:id",
      name: "Sessions",
      component: Sessions,
      props: true,
      meta: {
        requiresAuth: true,
        role: [1]
      }
    },
  ]
};

let accountPages = {
  path: "/account",
  component: DashboardLayout,
  redirect: "/account/main/",
  children: [
    {
      path: "main/:tab?",
      component: AccountMain,
      props: true,
      meta: {
        requiresAuth: true,
        role: [2,3]
      }
    },
    {
      path: "start/:id",
      name: "Start",
      component: Start,
      props: true,
      meta: {
        requiresAuth: true,
        role: [2,3]
      }
    },
    {
      path: "result/:id",
      name: "Result",
      component: Result,
      props: true,
      meta: {
        requiresAuth: true,
        role: [2,3]
      }
    },
    {
      path: "shared",
      name: "Shared",
      component: Shared,
      props: true,
      meta: {
        requiresAuth: true,
        role: [2,3]
      }
    },
    {
      path: "recent",
      name: "Recent",
      component: Recent,
      props: true,
      meta: {
        requiresAuth: true,
        role: [2]
      }
    },
    {
      path: "taked",
      name: "Taked",
      component: Taked,
      props: true,
      meta: {
        requiresAuth: true,
        role: [2,3]
      }
    }
  ]
};

let publicPages = {
  path: "/public",
  component: PublicLayout,
  redirect: "/public/start",
  children: [
    {
      path: "start/:id",
      name: "Start",
      component: PublicStart,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "result/:id",
      name: "Result",
      component: PublicResult,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
  ]
};

let sessionPage = {
  path: "/account/session/:id",
  name: "Session",
  component: Session,
  props: true,
  meta: {
    requiresAuth: true,
    role: [2,3]
  }
};

let publicSessionPage = {
  path: "/public/session/:id",
  name: "PublicSession",
  component: PublicSession,
  props: true,
  meta: {
    requiresAuth: false
  }
};

let profilePages = {
  path: "/profile",
  component: DashboardLayout,
  name: "ProfileManu",
  redirect: "/profile",
  children: [
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/changepwd",
      name: "ChangePassword",
      component: ChangePassword,
      meta: {
        requiresAuth: true
      }
    },
    { path: "*", component: NotFound }
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  redirect: "/login",
  children: [
    {
      path: "/login/:action?/:email?/:name?",
      name: "Login",
      component: Login,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/reset/:resetkey?",
      name: "Reset",
      component: Reset,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/logout",
      name: "Logout"
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/changepwd",
      name: "ChangePassword",
      component: ChangePassword,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/demo",
      name: "Demo",
      component: Demo,
      meta: {
        requiresAuth: false
      }
    },
    { name: "NotFound", path: "/notfound", component: NotFound }
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    name: "AccountMain",
    path: "/account/main",
    redirect: "/account/main/test"
  },
  adminPages,
  accountPages,
  sessionPage,
  publicPages,
  publicSessionPage,
  profilePages,
  authPages
];

export default routes;
