import axios from "axios";
import {api} from "./helper";
import Vue from "vue";
const client = axios.create({
  baseURL: api, // Vue.prototype.$apiUrl,
  withCredentials: false
});

client.interceptors.request.use(config => {
  return config;
});

client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  }
);

function Update(user) {
  user.action = "edit";
  return client.put("users.php", user);
}

function Create(user) {
  user.action = "create";
  return client.post("users.php", user);
}

function UserIdByEmail(user) {
  const params = {
    email: user.email,
    action: "idbyemail"
  };
  return client.get("users.php", { params });
}

function EmailByResetKey(resetkey) {
  const params = {
    resetkey: resetkey,
    action: "resetkeyemail"
  };
  return client.get("users.php", { params });
}

function Reset(user) {
  user.action = "reset";
  return client.put("users.php", user);
}

function SendResetEmail(user) {
  user.action = "resetemail";
  return client.post("users.php", user);
}

function GetUser(id) {
  const params = {
    id: id,
    action: "userbyid"
  };
  return client.get("users.php", { params });
}

function Delete(id) {
  const params = {
    id: id
  };
  return client.delete("users.php", { params });
}

function Auth(username, pwd) {
  const params = {
    username: username,
    pwd: pwd,
    action: "auth"
  };
  return client.get("users.php", { params });
}

function ChangePassword(pwd) {
  pwd.action = "changepwd";
  return client.put("users.php", pwd);
}

function Register(user) {
  user.action = "register";
  return client.post("users.php", user);
}

function Activate(user) {
  user.action = "activate";
  return client.post("users.php", user);
}

export {
  UserIdByEmail,
  Auth,
  GetUser,
  Update,
  ChangePassword,
  EmailByResetKey,Reset,SendResetEmail,
  Create,
  Delete,
  Register,
  Activate
};
