<template>
  <div class="row">
    <div class="col-lg-12">
      <edit-profile-form>

      </edit-profile-form>
    </div>
  </div>
</template>
<script>
  import EditProfileForm from './UserProfile/EditProfileForm.vue'

  export default {
    components: {
      EditProfileForm
    }
  }

</script>
<style>

</style>
