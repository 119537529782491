class UserType {
  constructor() {}
  id = "";
  dob = "";
  phone = "";
  email = "";
  fullname = "";
  address = "";
  city = "";
  country = "";
  aboutme = "";
  roleid = 1;
  rolename = "";
  picture = "";
  company = "";
  position = "";
  pwd = "";
  pwdtoken = "";
}

class FileType {
  id = "";
  filename = "";
  sourcetype = 0;
  sourceId = "";
  sourcedetId = "";
}

class CategoryType {
  constructor() {}
  id = "";
  name = "";
  description = "";
  uid = "";
}

class TestType {
  constructor() {}
  id = "";
  code = "";
  title = "";
  categoryid = "";
  globalcategoryid = "";
  description = "";
  shuffle = 0;
  active = 0;
  duration = 0;
  uid = "";
}

class SectionType {
  constructor() {}
  id = "";
  name = "";
  reference = "";
  testid = "";
  description = "";
  shuffle = 1;
  active = 1;
  orderindex = 0;
  scoremin = 1;
  questionsinexam = 1;
  uid = "";
}

class QuestionType {
  constructor() {}
  id = "";
  question = "";
  sectionid = "";
  rate = 1;
  answertype = 1;
  answercount = 1;
  active = 1;
  orderindex = 0;
  notes = "";
  answerdescr = "";
  uid = "";
}

class AnswerType {
  id = "";
  answer = "";
  questionid = "";
  active = 1;
  status = 0;
  uid = "";
}

class ShareType {
  id = "";
  testid = "";
  sharedate = "";
  validfrom = "";
  validto = "";
  email = "";
  onetime = 0;
  unregistered = 0;
  uid = "";
}


export {
  UserType,
  FileType,
  CategoryType,
  TestType,
  SectionType,
  QuestionType,
  AnswerType,
  ShareType
};
